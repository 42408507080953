
.about {
	display: none;
}

@media screen and (min-width: 660px) {
	.form input[type="submit"] {
		width: 300px;
	}
}

@media screen and (min-width: 910px) {
	.contact-page {
		width: 100%;
		box-sizing: border-box;
	}

	.contact-page::after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
	}

	.about {
		width: 30%;
		float: right;
		padding-left: 40px;
		box-sizing: border-box;
		height: 100%;
	}

	.about img {
		width: 150px;
		height: 150px;
		border: 1px solid;
		padding: 5px;
	}
	.about p {
		padding-top: 10px;
		font-size: 1rem;
	}

	.form {
		width: 70%;
		float: left;
		box-sizing: border-box;
	}

	.about,
	.form {
		display: inline-block;
	}
}

@media screen and (min-width: 1500px) {
	.contact-page {
		padding: 2.5%;
		width: 90%;
		margin: 0 auto;
	}

	.about {
		padding-left: 80px;
	}
}