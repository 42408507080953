/* what's in it for you? */
.introduction h2 {
	font-family: 'Pathway Gothic One', sans-serif;
	text-transform: uppercase;
	font-weight: normal;
	margin-top: 0;
	font-size: 1.75rem; /* 28px */
}

.introduction {
	width: 100%;
	background-color: #fff3f2;
	box-sizing: border-box;
}

.introduction div {
	background-color: #fff;
	color: #000;
	padding: 15px;
	width: 100%;
	max-width: 600px;
	box-sizing: border-box;
	margin: 0 auto;
	box-shadow: 0 8px 6px -6px #a6a6a6;
}

/* ABOUT ME */

.aboutMe {
	width: 100%;
	background-color: #000;
  background-image: url('https://theproactivedev.github.io/eiringonzales/assets/me.jpg');
  background-size: cover;
  background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: top center;
  height: 410px;
  box-sizing: border-box;
}

.aboutMe div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	background-color: rgba(255,255,255, 0.7);
	box-sizing: border-box;
	box-shadow: none;
	padding: 20px;
}

.aboutMe .bold {
	font-family: 'Pathway Gothic One', sans-serif;
	text-transform: uppercase;
	font-size: 1.5625rem;
}

.aboutMe p:first-of-type {
	font-size: 2.1875rem;
	font-style: italic;
	font-family: 'Playfair Display', serif;
}

.certificate {
	width: 100%;
	box-sizing: border-box;
}

.certificate div:first-of-type {
	padding-bottom: 15px;
}

.certificate img {
	width: 100%;
	box-shadow: 0 8px 6px -6px #a6a6a6;
	display: block;
	margin: 0 auto;
	max-width: 500px;
}

.certificate img:hover {
	box-shadow: 0 0 20px 0 #c4c4c4;
}

/* ANIMATIONS */

.slideUp {
	-webkit-transform: translateY(200px);
	transform: translateY(200px);
	animation: slideUp 1s ease forwards;
}

.slideUp:nth-child(odd) {
  animation-duration: 0.6s;
}

.visible {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	animation: none;
}

@keyframes slideUp {
	to {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.work {
	display: flex;
  background: url('https://theproactivedev.github.io/eiringonzales/assets/code.jpg') no-repeat center;
  background-size: cover;
  background-attachment: fixed;
	height: 40vh;
	padding: 20px;
}

.work-field {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 0 20px;
	background-color: rgba(255,255,255, 0.8);
	text-align: center;
}

.work-field p:first-of-type {
	font-size: 1.5rem;
	margin-bottom: 30px;
}

.clickHere {
	font-size: 1.625rem; /* 26px */
	font-family: 'Pathway Gothic One', sans-serif;
}

@media screen and (min-width: 660px) {
	.aboutMe {
		overflow: hidden;
		position: relative;
	}

	.aboutMe div {
		margin: 0;
		height: 100%;
		width: 55%;
		position: absolute;
		top: 0;
		left: 0;
	}
}

@media screen and (min-width: 910px) {
	.work {
		overflow: hidden;
		position: relative;
	}

	.work .work-field {
		margin: 0;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
	}

	.certificate {
		display: table;
	}

	.certificate > div {
		width: 49%;
		display: table-cell;
		padding-right: 1%;
		padding-bottom: 3%;
	}

	.certificate > div:last-of-type {
		padding-right: 0;
		padding-left: 1%;
	}
}

@media screen and (min-width: 920px) {
	.aboutMe div {
		padding-left: 7.5%;
	}

	.work .work-field {
		width: 35%;
	}

	.work-field div {
		height: auto;
		position: relative;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}

@media screen and (min-width: 1200px) {
	.aboutMe div {
		width: 38%;
	}
}
