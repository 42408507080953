/* FOOTER */
footer {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
	padding: 2.5% 20px;
  background-color: #2f2f30;
	box-sizing: border-box;
}

footer h4 {
  color: #fff;
	margin: 10px 0;
}

footer p {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 0;
  color: #c4c4c4;
	font-size: 15px;
}

footer .row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
	padding-bottom: 20px;
}
footer .row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

footer a {
	color: #fff3f2;
}

footer a:hover {
  color: #ddd;
}

.web li {
	padding-right: 10px;
	display: inline-block;
	margin: 0 auto;
}

.web li:last-of-type {
	padding-right: 0;
}

footer .fa {
	font-size: 1.875rem; /* 30px */
	display: block;
}

.web li:hover a {
	color: #555555;
}


@media screen and (min-width: 768px) {
	footer .row > div {
		box-sizing: border-box;
		padding-left: 15px;
	}
}

@media screen and (min-width: 1020px) {
	footer .row {
		padding-bottom: 35px;
	}

	footer .row > div {
		width: 33.33%;
	}

	footer .row div {
		border-right: 1px solid #fff;
	}

	footer .row div:first-of-type {
		padding-left: 0;
		padding-right: 15px;
	}

	footer .row div:last-of-type {
		border-right: none;
	}

	footer .row + p {
		text-align: center;
	}
}